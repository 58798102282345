.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ReactGridGallery_tile {
  background: rgba(0, 0, 0, 0) !important;
}
.ReactGridGallery_tile-viewport {
  border-radius: 20px 20px 0 0;
}
.ReactGridGallery_tile-description {
  bottom: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8) !important;
  color: white;
}
/* .custom-overlay__caption { */
/*   background-color: rgba(0, 0, 0, 0.8); */
/*   max-height: 240px; */
/*   overflow: hidden; */
/*   position: absolute; */
/*   bottom: 0; */
/*   width: 100%; */
/*   color: white; */
/*   padding: 2px; */
/*   font-size: 90%; */
/* } */
/* .custom-overlay__tag { */
/*   word-wrap: break-word; */
/*   display: inline-block; */
/*   background-color: white; */
/*   height: auto; */
/*   font-size: 75%; */
/*   font-weight: 600; */
/*   line-height: 1; */
/*   padding: 0.2em 0.6em 0.3em; */
/*   border-radius: 0.25em; */
/*   color: black; */
/*   vertical-align: baseline; */
/*   margin: 2px; */
/* } */
